<template>
<div>
    
    <v-container>
        
        
        <v-layout row wrap justify-space-around>
            
            <v-flex xs11 md11>

                <v-row justify="center" class="text-center pa-0 ma-0">
                    <v-card-title v-text="'About Us'"></v-card-title>
                </v-row>

                <v-card color="white" outlined>
                    
                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>

                            <v-card-text>

                                <div class="AboutUs">

                                    <p>
                                        Preskilet stands for presenting your soft skill set !
                                        Preskilet is the world’s first professional video platform
                                        , At preskilet you can create your professional video
                                        resume containing videos about projects and achivements
                                        etc. You can share your video profile with potential
                                        recruiters for increasing chances of placements. With
                                        preskilet your video profile is live at
                                        <b>https://preskilet.com/your email</b> .
                                    </p>

                                </div>

                            </v-card-text>

                        </div>

                    </div>

                </v-card>

                <br />
                <br />
                <br />

            </v-flex>
            
        </v-layout>
        
    </v-container>
    
</div>
</template>

<style scoped>

h2 {
    margin-bottom   : 3%;
}

</style>

<script>

import { mapGetters } from "vuex";

import firebase from "firebase/app";
import "firebase/firebase-analytics";
var firebaseAnlytics;

export default {
    
    name        : "AboutUs",
    
    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    created() {

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("About Us Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        
    }
    
};

</script>
