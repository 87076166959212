<template>
    <div>
        
        <!-- <ExportExcelSheetVueJs 
            :sheetData="sheetrows"
            :columns="columns"
            :filename="filename"
            :sheetname="sheetname"
            id="exportExcelButtonTag"
        ></ExportExcelSheetVueJs> -->
    
        <VueExcelXlsx 
            :sheetrows="sheetrows"
            :columns="columns"
            :filename="filename"
            :sheetname="sheetname"
            id="exportExcelButtonTag"
        ></VueExcelXlsx>
    
        <v-dialog 
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogExcel"
            persistent
        >
            <v-card>
                
                <v-toolbar color="green" dark flat>
                    
                     <v-progress-circular
                     class="ma-3 pa-3"
                                        
                                        :size="30"
                                        color="white"
                                        dark
                                        indeterminate
                                    ></v-progress-circular>
    
                    Downloading Excel</v-toolbar>
                
                <v-card-text class="align-center">
                    <v-img  :src="downloadExcelGif"></v-img>
                    <div color="primary" class="text-h6 pa-2">Please wait,  Let us write the grades for you !</div>
                </v-card-text>
                
                <v-card-actions class="justify-end">
                    
                    <v-btn text @click="cancelExcelDownload">Cancel</v-btn>
                
                </v-card-actions>
            
            </v-card>
        
        </v-dialog>
    
        <v-btn
          class="hidden-md-and-up"
          dark
          fab
          color="primary"
          style="position:fixed;bottom:3%;right:5%;z-index:1000;"
          v-on:click="addGroupForm = !addGroupForm"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
    
        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card>
                <v-card-title class="headline">Are you sure ?</v-card-title>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    
                    <v-btn color="green darken-1" text @click="dialogDelete = false">No</v-btn>
                    <v-btn color="green darken-1" text @click="deleteRubricSheet">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-container>
            
            <v-layout row wrap justify-space-around>
                
                <v-flex xs11 md6>
    
    
                    <div class="text-right">
                        <v-btn class="ma-2" color="primary" depressed v-on:click="addGroupForm = !addGroupForm">
                            <v-icon dark left>mdi-plus</v-icon>Create Rubrics Sheet
                        </v-btn><br />
                    </div>
                    
                    <v-card
                        flat
                        v-if="rubricSheetList.length === 0 && !addGroupForm"
                        class="mx-auto"
                        max-width="400"
                    >
    
                        <v-img class="white--text align-end" :src="emptyStateImage"></v-img>
    
                        <div class="d-flex flex-no-wrap justify-space-between">
    
                            <div>
    
                                <v-card-title
                                class="headline"
                                v-text="'Group'"
                                ></v-card-title>
    
                                <v-card-subtitle v-text="emptyStateMessage"></v-card-subtitle>
    
                            </div>
    
                        </div>
    
                    </v-card>
                    
                    <div v-if="addGroupForm">
                        <v-card color="white" outlined >
                            
                 
                            <v-form ref="form" v-model="valid" lazy-validation class="form-container">
                                
                                <v-text-field
                                    v-model="rubricSheetName"
                                    clearable
                                    counter="100"
                                    :rules="groupNameRules"
                                    label="Custom Rubrics Template Name"
                                    required
                                    :disabled="disabled"
                                ></v-text-field>

                                <v-text-field
                                    v-model="groupDescription"
                                    clearable
                                    counter="100"
                                    label="Type and hit enter to add rubrics parameter"
                                    required
                                    :disabled="disabled"
                                    append-outer-icon="mdi-send"
                                    @click:append-outer="addParamter"
                                    v-on:keyup.enter="addParamter"
                                ></v-text-field>

                                <br />

                                <v-list-item-title> <b>Preview of the Sheet</b>
                                <p style="color: red;">( For consistancy, you can not delete once a rubrics sheet is created. )</p>
                                </v-list-item-title>

                                <br />

                                <v-list>
                                    <div style="border: 1px solid grey;margin: 2%;border-radius: 5px;">
                                        <v-list-item >
                            <v-list-item-content>
                                <v-list-item-title>Sheet Name: {{ rubricSheetName }} </v-list-item-title>
                                <v-list-item-subtitle class="text-wrap">
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                                          
                        </div>


    <table style="border: 1px solid grey;margin: 2%;border-radius: 5px;">
                                        <tr>
                                            <th style="width: 70%;">Rubric Name</th>
                                            <th>Editable in final excel sheet (Currently out of 5)</th>
                                            <th></th>
                                        </tr>
                                            <tr v-for="item in parameterList">
                                                <td><div style="color: #1976d2 !important;margin: 5%;">{{item.name}}</div></td>
                                                <td><v-rating color="primary" background-color="primary darken-1" readonly></v-rating></td>
                                                <td>
                                                    <v-btn icon @click="deleteParamter(item.id)">
                                                        <v-icon color="primary">mdi-delete</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>                                       

                                    </table>


</v-list>
                             
                                <br />
                                
                                <div class="text-right">
                                    <v-btn class="ma-2" color="primary" depressed v-on:click="validate" :disabled="disabled" required>
                                        <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Create
                                    </v-btn>
                                    <v-btn
                                        class="ma-2"
                                        color="primary"
                                        depressed
                                        v-on:click="addGroupForm = !addGroupForm"
                                        >
                                            <v-icon dark left>mdi-close-circle</v-icon>cancel
                                        </v-btn>
                                </div>
                            </v-form>
                        </v-card> <br />
                    </div>
                    
                    <v-list v-if="rubricSheetList.length != 0" subheader two-line >
                        <v-subheader> <b>Custom Rubrics sheets created by you</b> </v-subheader>
                        
                        <div v-for="item in rubricSheetList" :key="item._id" style="border: 1px solid grey;margin: 2%;border-radius: 5px;">
                            <v-list-item >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle class="text-wrap">

                                </v-list-item-subtitle>
                                
                            </v-list-item-content>
                            
                            <!-- Note - User should not be able to delete sheet once created, because it could be attached with any video which he / she might have graded -->
                            <!-- <v-list-item-action>
                                <v-btn icon @click="deleteRubricSheetById(item._id)">
                                    <v-icon color="primary">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action> -->
                        </v-list-item>
                            <table>
                                        <tr>
                                            <th style="width: 70%;">Rubric Name</th>
                                            <th>Editable in final excel sheet (Currently out of 5)</th>
                                            <!-- <th></th> -->
                                        </tr>
                                            <tr v-for="param in item.rubricParameters" :key="param._id" >
                                                <td><div style="color: #1976d2 !important;margin: 2.5%;">{{param.name}}</div></td>
                                                <td><v-rating color="primary" background-color="primary darken-1" readonly></v-rating></td>
                                                <!-- <td>
                                                    <v-btn icon> <v-icon color="primary">mdi-delete</v-icon>
                                                    </v-btn>
                                                </td> -->
                                            </tr>                                       

                                    </table>


                            
                                </div>
                    </v-list>
                
                </v-flex>
                
                <v-flex xs11 md4>
    
                    <!-- <v-card 
                        :flat="cardFlatFlag"
                        :outlined="cardOutlineFlag"
                        class="mx-auto sticky"
                    >
                
                        <v-list-item two-line class="RightSideAd">
                            
                            <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                            
                            <v-list-item-content>
                                
                                <v-list-item-title>Google Ads</v-list-item-title>
                                
                                <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                            
                            </v-list-item-content>
                                        
                            <v-btn color="primary" size="35" tile large icon router >
                                <v-icon left> mdi-compass-outline</v-icon>
                            </v-btn>
    
    
                        </v-list-item>
                        
                            <div class="ad-container">
                                <Adsense
                                    style="display:block"
                                    data-ad-client="ca-pub-2789565594210548"
                                    data-ad-slot="2961311238"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"
                                ></Adsense>
                            </div>
            
            
                    </v-card> -->
    
                </v-flex>
    
            </v-layout>
        </v-container>
    </div>
    </template>
    
    <style scoped>
    
    .sticky{
    position: relative;
    }
    
    
    
    .RightSideAd{
        padding-left: 0px;
        padding-right: 0px;
    }
    
    /* for desktop */
    @media screen and (min-width: 650px) {
    .RightSideAd{
        padding-left: 4%;
        padding-right: 4%;
    }
    
    .sticky{
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 2;
        width: auto !important;
    }
    
      .sticky2{
        position: -webkit-sticky;
        position: sticky;
        top: 27rem;
        z-index: 2;
        width: auto !important;
        margin-top: 280%;
    }
    }
    
    .form-container {
      padding   : 20px;
    }
    
    
    </style>
    
    <script>
    import XLSX from 'xlsx/xlsx';
    window.$ = window.jQuery = require('jquery');
    
    import { mapGetters } from "vuex";
    import utilities from "@/helpers/utilities";
    import apis from "../helpers/apiConfig";
    const axios = require("axios").default;
    import VueExcelXlsx from "@/components/VueExcelXlsx";
    
    import firebase from "firebase/app";
    import "firebase/firebase-analytics";
    var firebaseAnlytics;
    
    import ExportExcelSheetVueJs from "export-excel-sheet-vue-js";
    
    // Google Adsense    
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InFeedAdsense)
    
    export default {
        
        name: "Followers",
        
        components: { VueExcelXlsx, ExportExcelSheetVueJs},
        
        computed: { ...mapGetters({ userData: "User/userData" }) },
        
        mounted() {
    
            if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
            else { this.cardOutlineFlag = true; }
    
            // FIREBASE ANLYTICS
            firebaseAnlytics = firebase.analytics();
            firebaseAnlytics.logEvent("Custom Rubrics Page");
    
            this.$store.commit("User/toggleProgressLoader", false);
            this.$store.dispatch("User/updateUserToken", null, { root: true });
            this.$store.commit("User/isSignedIn");
    
            this.setYears();
            this.getRubricSheetList();
    
        },
        
        data() {
            
            return {
                parameterList: [],

    
                cardFlatFlag    : true,
                cardOutlineFlag : false,
    
                disabled : false,
                valid : true,
                dialogExcel: false,
                dialogDelete: false,
                deleteGroupId : "",
    
                addGroupForm: false,
                rubricSheetName : "",
                groupNameRules:  [ 
                    (v) => !!v || "Template Name is required",
                    (v) => (v && v.length <= 100) || "Template Name must be less than 100 characters"
                ],
                
                groupDescription : "",
                groupDescriptionRules:  [ 
                    (v) => (v && v.length <= 100) || "Group Name must be less than 100 characters"
                ],
    
                subjectName : "",
                subjectTerms: [],
                subjectNameRules : [ 
                    (v) => !!v || "Subject Name is required",
                    (v) => (v && v.length <= 100) || "Subject Name must be less than 100 characters"
                ],
    
                subjectType: "",
                subjectTypes: ["Theroy", "Lab"],
    
                academicYear: "",
                academicYears: ['First Year', 'Second Year', 'Third Year', 'Fourth Year'],
    
                departmentName: "",
                departmentTerms       : ["School Of Electrical Engineering","School Of Computer Engineering & Technology","School Of Mechanical & Civil Engineering","School Of Chemical Engineering","School Of Design","School Of Humanities And Engineering Sciences","Computer Department","Information Technology Department","Electrical Department","Civil Department","Mechanical Department"],
                departmentNameRules:[
                    (v) => (v && v.length <= 100) || "Department Name must be less than 100 characters"
                ],
    
                divName: "",
                divTerms: [],
                divNameRules:[ 
                    (v) => (v && v.length <= 100) || "Division Name must be less than 100 characters"
                ],
                
                batchName: "",
                batchTerms: [],
                batchNameRules:[ 
                    (v) => (v && v.length <= 100) || "Batch Name must be less than 100 characters"
                ],
    
                year: "",
                years: [],
    
                // Test data
                rubricSheetList: [
                    // {
                    //     id: 1,
                    //     name: "Speech on your favorite topics in current affairs",
                    //     rubricParameters: [
                    //         "Content Relevance",
                    //         "Appearacnce",
                    //         "Use of visual aids",
                    //         "Clarity of Message",
                    //         "Depth of Research",
                    //         "Supporting Evidence",
                    //         "Originality",
                    //         "Engagement"
                    //     ],
                    // },
                    // {
                    //     id: 2,
                    //     name: "Foreign Language Activity",
                    //     rubricParameters: [
                    //         "Vocabulary",
                    //         "Grammar",
                    //         "Pronunciation",
                    //         "Fluency",
                    //         "Sentence Structure",
                    //     ]
                    // },
                ],
                downloadExcelGif : utilities.emptyState.excel.image,
                emptyStateImage: utilities.emptyState.group.image,
                emptyStateMessage: utilities.emptyState.group.message,
    
                //Static excel data
                    columns : [
                        {
                            label: "Student Name",
                            field: "studentName",
                        },
                        {
                            label: "Registration Number / PRN",
                            field: "regno",
                        },
                        {
                            label: "Exam Seat Number",
                            field: "seatno",
                        },
                        {
                            label: "Roll Number",
                            field: "rollno",
                        },
                        {
                            label: "Division",
                            field: "div",
                        },
                        {
                            label: "Batch",
                            field: "batch",
                        },
                   
                    ],
                    sheetrows : [],
                    filename: "Excel File of Grades",
                    sheetname: "Excel Sheet of Grades"
            };
            
        },
    
        methods: {
    
    
            setYears(){ var i = -1; while(i < 20){ this.years.push(new Date().getFullYear() + i); i++;}},
            
            validate() {
                this.$refs.form.validate();
                if (this.$refs.form.validate()) {
                    this.addRubricSheet();
                }
            },
            
            reset() { this.$refs.form.reset() },
            
            addParamter () {
                if (!this.groupDescription) {
                    this.$store.commit("User/showErrorAlert", "Please type something");
                } else if (this.groupDescription.length > 100) {
                    this.$store.commit("User/showErrorAlert", "Parameter length should be less than 100 characters");
                } else  {
                    console.log("At start parameterList : ", this.parameterList);

                    const ifExist = this.parameterList.find(item => {
                        console.log("item.name : ", item.name);
                        console.log("this.groupDescription : ", this.groupDescription);
                        return item.name === this.groupDescription;
                    });
                
                    if(ifExist) {
                        this.$store.commit("User/showErrorAlert", "Duplicate Entry");
                    } else {
                        this.parameterList.push(
                        {
                            id: this.parameterList.length,
                            name: this.groupDescription,
                        }
                        );
                        this.groupDescription = "";
                    }
                }
            
            },
   
            deleteParamter (deleteParamterId) {
                const filterArray = this.parameterList.filter((parameter) => {
                    return parameter.id != deleteParamterId
                })
                this.parameterList = filterArray;
            },

            addRubricSheet(){
                console.log("Add Rubric sheet clicked : ");
                
                if (this.parameterList.length <= 0) {
                    this.$store.commit("User/showErrorAlert", "Please add at least 1 Parameter");
                } else {
                    this.$store.commit("User/toggleProgressLoader", true);
                    
                    const rubrics = this.parameterList.map((item) => { return item.name; });
                
                let data = {
                    sheetName : this.rubricSheetName,
                    rubrics   : rubrics,
                };
    
                console.log("addRubricSheet data : ", data);
                
                let endPoint = apis.urls.createRubricSheet;
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
    
                    console.log("success : ", res.data.result.message);
                    this.$store.commit("User/toggleProgressLoader", false);
                    this.disabled = false;
                    this.$store.commit("User/showSuccessAlert", res.data.result.message);
                    this.reset();
                    this.getRubricSheetList();
    
                    this.addGroupForm = false;
                    this.rubricSheetName = "";
                    this.parameterList = [];
    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Group Created");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    (this.disabled = false), console.log(err);
                    let errorMsg = utilities.errorHandler(err);
                    console.log("Error in Pret",err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
                    
                }

            },
    
            getRubricSheetList(){
                
                let endPoint = apis.urls.getRubricSheetList;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("Custom Rubrics data : ",res);
                    
                    let data = res.data.result.data;
                    console.log("rubricSheetList that we got from backend : ", data)

                    this.rubricSheetList = [
                        ...data.reverse(), 
                        {
                            name: "Default Presentation Rubric Sheet",
                            rubricParameters: [
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Language Proficiency",
                                },
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Voice Modulation",
                                }, 
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Eye Contact, Hand Gestures and Body Movement",
                                }, 
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Confidence",
                                }, 
                                {
                                    sheet_id: "303030303030303030303030",
                                    name: "Content Delivery and Knowledge",
                                }, 
                            ],
                            _id: "303030303030303030303030"
                        }
                    ];
                    // console.log("data : ", data)
                    this.$store.commit("User/toggleProgressLoader", false);
    
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Group Data Displayed");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Following",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
            },
    
            cancelExcelDownload(){
                this.dialogExcel = false;
                this.$store.commit("User/toggleProgressLoader", true);
                window.location.reload();
            },
    
            downloadGroupExcel(groupId){
                this.dialogExcel = true;
                
                this.$store.commit("User/toggleProgressLoader", true);
                
                let endPoint = apis.urls.getGroupExcelData + "?groupId=" + groupId;
                
                axios(endPoint, {
                    method  : "get",
                    withCredentials: true,
                    headers: { authorization: this.userData.token },
                })
                .then((res) => {
                    
                    console.log("Group Excel Data : ", res.data.result.data);
                    
                    console.log("filename : ",this.filename);
                console.log("filename   w : ",res.data.result.data.rubricSheetName);
                this.filename = res.data.result.data.rubricSheetName
                console.log("filename : ",this.filename);
    
    
    
                    this.generateExcel(res.data.result.data)
                    this.$store.commit("User/toggleProgressLoader", false);
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Group Excel Sheet Downloaded");
                })
                .catch((err) => {
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log(err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
    
    
            },
    
            deleteRubricSheetById(groupId){
                this.deleteGroupId = groupId;
                this.dialogDelete = true;
            },
    
            deleteRubricSheet(){
                
                this.dialogDelete = false;
                
                this.$store.commit("User/toggleProgressLoader", true);
                
                let endPoint = apis.urls.deleteRubricSheet + "?rubricSheetId=" + this.deleteGroupId;
                
                axios(endPoint, {
                    method  : "delete",
                    withCredentials: true,
                    headers: { authorization: this.userData.token },
                })
                .then((res) => {
                    
                    console.log("success : ", res.data.result.message);
                    this.$store.commit("User/toggleProgressLoader", false);
                    this.$store.commit("User/showSuccessAlert", res.data.result.message);
                    this.getRubricSheetList();   
    
                    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Group Deleted");
                })
                .catch((err) => {
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log(err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            },
    
            generateExcel(data){
                console.log("Proper Assignment of data is reamingn  : ", data);
                
                //Making columns empty first 
                this.columns =[
                        {
                            label: "Student Name",
                            field: "studentName",
                        },
                        {
                            label: "Registration Number / PRN",
                            field: "regno",
                        },
                        {
                            label: "Exam Seat Number",
                            field: "seatno",
                        },
                        {
                            label: "Roll Number",
                            field: "rollno",
                        },
                        {
                            label: "Department",
                            field: "department",
                        },
                        {
                            label: "Division",
                            field: "div",
                        },
                        {
                            label: "Batch",
                            field: "batch",
                        },
                   
                    ];
                
                //setting columns
                var tasks = data.tasks
                
                for (let index = 0; index < tasks.length; index++) {
                    
                    var element = { label: tasks[index].name, field: tasks[index]._id }
                    
                    this.columns.push(element)
                }
                
                //Making sheetrows empty first 
                this.sheetrows = [];
                
                //setting data
                var excelData = data.excelData
    
                if(excelData.length == 0){
                    this.$store.commit("User/showErrorAlert", "You have not Graded any Video for this Group");
                    this.dialogExcel = false;
                }else
                {
    
                    
    
                for (let index = 0; index < excelData.length; index++) {
                    
                    var element = 
                    {
                        studentName : excelData[index].userData.name,
                        regno       : excelData[index].userData.regno,
                        seatno      : excelData[index].userData.seatno,
                        rollno      : excelData[index].userData.rollno,
                        department  : excelData[index].userData.department,
                        div         : excelData[index].userData.div,
                        batch       : excelData[index].userData.batch,
                    }
                    
                    if(excelData[index].taskGrades.length != 0 ){
                        let myTasks = excelData[index].taskGrades;
    
                        for (let j = 0; j < myTasks.length; j++) {
                            
                            console.log("taskId : ", myTasks[j].taskId);
                            console.log("avgGrades : ", myTasks[j].avgGrades);
                            element[myTasks[j].taskId] = myTasks[j].avgGrades;
                    
                        }
    
                    }
                    
                    this.sheetrows.push(element)
                }
    
                console.log("this.columns =  ",this.columns);
                console.log("this.sheetrows =  ",this.sheetrows);
                
                // document.getElementById("exportExcelButtonTag").click();
                this.exportExcel();
                
                // setTimeout(() => {
                    
                // }, 5000);
    
            }
    
            },
            
            exportExcel(){
                console.log("exportExcel is called now..................");
    
                      let createXLSLFormatObj = [];
                    let newXlsHeader = [];
                    let vm = this;
                    console.log("This in Excel Data : ", this);
                    console.log("This in Excel Data : ", this.sheetrows);
                    if (vm.columns.length === 0){
                        console.log("Add columns!");
                        return;
                    }
                    
                    if (vm.sheetrows.length === 0){
                        console.log("Add sheetrows!");
                        return;
                    }
                    $.each(vm.columns, function(index, value) {
                        newXlsHeader.push(value.label);
                    });
    
                    createXLSLFormatObj.push(newXlsHeader);
                    $.each(vm.sheetrows, function(index, value) {
                        let innerRowData = [];
                        $.each(vm.columns, function(index, val) {
                            if (val.dataFormat && typeof val.dataFormat === 'function') {
                                innerRowData.push(val.dataFormat(value[val.field]));
                            }else {
                                innerRowData.push(value[val.field]);
                            }
                        });
                        createXLSLFormatObj.push(innerRowData);
                    });
    
                    let filename = vm.filename + ".xlsx";
    
                    let ws_name = vm.sheetname;
    
                    let wb = XLSX.utils.book_new(),
                        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, filename);
                    
                    this.dialogExcel = false;
                this.$store.commit("User/showSuccess Alert", "Excel Sheet Downloaded, Check your Downloads !");
    
    
            },
            
    
    
        }
    
    };
    </script>
    