<template>
<div>
    
    <v-dialog v-model="dialog" max-width="290">
        <v-card>
            
            <v-card-title class="headline">Are you sure ?</v-card-title>

            <v-card-actions>
                
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">No</v-btn>
                <v-btn color="green darken-1" text @click="DeleteVideoOnFirabase"
                >Yes
                </v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>
    
    <v-container>
        
        <v-layout row wrap justify-space-around>
            
            <v-flex xs11 md6>

                <v-card
                flat
                v-if="prets.length === 0"
                class="mx-auto"
                max-width="400"
                >
                    <v-img class="white--text align-end" :src="emptyStateImage"></v-img>
                    
                    <div class="d-flex flex-no-wrap justify-space-between">

                        <div>
                            <v-card-title class="headline" v-text="'Prets'"></v-card-title>

                            <v-card-subtitle v-text="emptyStateMessage"></v-card-subtitle>
                        </div>

                    </div>

                </v-card>
                
                <div v-for="item in prets" :key="item.id">

                    <v-list subheader three-line outlined>

                        <v-list-item>

                            <v-list-item-content>
                                
                                <v-list-item-subtitle>Title</v-list-item-subtitle>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>

                            </v-list-item-content>

                            <div class="text-right">

                                <v-btn
                                    class="ma-2"
                                    color="primary"
                                    icon
                                    @click="deletePret(item._id, item.firebaseName)"
                                >
                                
                                    <v-icon large>mdi-delete</v-icon>

                                </v-btn>

                            </div>

                        </v-list-item>

                        <v-list-item>

                            <v-list-item-content>

                                <v-list-item-subtitle>Description</v-list-item-subtitle>
                                <br />
                                <br />
                                <p>{{ item.description }}</p>

                            </v-list-item-content>

                        </v-list-item>

                        <v-list-item>

                            <v-list-item-content>

                                <v-list-item-subtitle>Video</v-list-item-subtitle>
                                <br />
                                <br />
                                <v-list-item-title>

                                    <video
                                    style="margin: 5px;"
                                    controls
                                    :src="item.videoUrl"
                                    height="165"
                                    alt="Your Pret"
                                    ></video>

                                </v-list-item-title>

                            </v-list-item-content>

                        </v-list-item>

                    </v-list>

                    <br />

                </div>

            </v-flex>

            <v-flex xs11 md4>

                 <!-- <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>

                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="9783732271"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
        
                </v-card> -->

            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>

.form-container {
  padding   : 20px;
}

/* for desktop */
@media screen and (min-width: 650px) {

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}


}


</style>

<script>

import { mapGetters } from "vuex";

import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firebase-analytics";

var firebaseAnlytics;
// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)


export default {
    
    name        : "editPret",

    computed    : { ...mapGetters({ userData: "User/userData" }) },
    
    
    mounted() {

          
        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Edit Pret Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");
        this.setPret();
    
    },
    
    data() {
        
        return {
             cardFlatFlag    : true,
            cardOutlineFlag : false,

            
            emptyStateImage: utilities.emptyState.prets.image,
            emptyStateMessage: utilities.emptyState.prets.message,
            dialog: false,
            deletePretId: "",
            deletefirebaseName: "",
            disabled: false,
            valid: true,
            prets: []
        };

    },
    
    methods: {
        
        DeleteVideoOnFirabase() {
            
            // var storage = firebase.storage();
            // var storageRef = storage.ref();
            // var desertRef = storageRef.child(this.deletefirebaseName);
            var that = this;
            that.deletePretById();

            // desertRef
            // .delete()
            // .then(function() {
            //     that.deletePretById();
            // })
            // .catch(function(error) {
                
            //     console.log("firebase error while delete the video ", error);
            //     let errorMsg = "Something went wrong, Please try again later.";
            //     that.$store.commit("User/showErrorAlert", errorMsg);
            
            // });

        },
        
        deletePret(videoId, firebaseName) {
            
            this.deletePretId = videoId;
            this.deletefirebaseName = firebaseName;
            this.dialog = true;
        
        },
        
        deletePretById() {
            
            this.dialog = false;
            this.$store.commit("User/toggleProgressLoader", false);

            var data        = { videoId : this.deletePretId };
            let endPoint    =   apis.urls.pret;
            
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "delete",
                withCredentials : true,
                data,
                headers         : { authorization   : this.userData.token }
            })
            .then((res) => {
            
                console.log("success : ", res);
                this.$store.commit("User/toggleProgressLoader", false);
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                this.setPret();

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Pret Deleted");
            
            })
            .catch((err) => {
            
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in EditPret",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },
        
        setPret() {
            
            let endPoint = apis.urls.preskilet + "?user=" + this.userData.email;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization   : this.userData.token } })
            .then((res) => {
                
                console.log("preskilet data came from backend : ", res.data.result.data.prets);
                this.$store.commit("User/toggleProgressLoader", false);
                this.prets = res.data.result.data.prets;

            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in EditPret",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
                
            });

        }
        
    }

};

</script>
