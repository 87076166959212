<template>
    <div>
        
        <v-container>
            
            <v-layout row wrap justify-space-around>
    
                <v-flex xs11 md6>
    
    
                    <v-card color="white" outlined>
    
                        <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="form-container"
                        >
    
                        <v-alert v-if="subscriptionErrorMessage" color="error" dense outlined type="error">
                            <h3 class="text-h5">
                                Only 1 video upload attempt
                            </h3>
                            <div>Thank you for choosing our platform!
                            You currently have 1 video credit, allowing you to only upload 1 video.
                            If you want upload additional videos,
                            <strong style="color: #2196F3;"> kindly reach out to your college management </strong>
                            to expand your video upload limit.</div>

                            <v-divider
                                class="my-4 info"
                                style="opacity: 0.22"
                            ></v-divider>

                            <strong style="color: green;">
                                        Or else You can recharge and get more video uploads credits by yourself
                                    </strong>

                            <v-row
                                align="center"
                                no-gutters
                            >
                                <v-col>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <router-link style="text-decoration: none;color:black;" to="/payments">
                                        <v-btn style="color: white;background-color: #2196F3;margin: 15px;">
                                            Recharge / Pay
                                        </v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                            </v-alert>

                        <v-alert v-if="subscriptionBalanceExpiredByCredits || subscriptionBalanceExpiredByDate" color="error" dense outlined type="error">
                            <h3 class="text-h5">
                                Subscription Expired
                            </h3>
                            <br />
                            <div>
                                Thank you for choosing our platform!
                                Your subscription has been expired because
                                <span v-if="subscriptionBalanceExpiredByCredits" style="color: black;">exipry date is passed</span>
                                <span v-if="subscriptionBalanceExpiredByDate" style="color: black;">video credit is reached to zero (0)</span>
                                If you want upload additional videos,
                            <strong style="color: #2196F3;"> kindly reach out to your college management </strong>
                            to expand your video upload limit.</div>

                            <v-divider
                                class="my-4 info"
                                style="opacity: 0.22"
                            ></v-divider>

                            <strong style="color: green;">
                                        Or else You can recharge and get more video uploads credits by yourself
                                    </strong>

                            <v-row
                                align="center"
                                no-gutters
                            >
                                <v-col>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <router-link style="text-decoration: none;color:black;" to="/payments">
                                        <v-btn style="color: white;background-color: #2196F3;margin: 15px;">
                                            Recharge / Pay
                                        </v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                        </v-alert>
                            
                        <v-alert v-if="!subscriptionErrorMessage && !subscriptionBalanceExpiredByDate && !subscriptionBalanceExpiredByCredits" dense outlined color="primary">
                            You currently have <span style="color:red">{{ subscriptionBalanceLimit }} video upload attempt</span>, allowing you to upload {{ subscriptionBalanceLimit  }} videos.
                            If you want upload additional videos,
                            <strong> Please recharge from Payment section or kindly reach out to your college management </strong>
                            to expand your video upload limit.
                        </v-alert>

                        <v-alert v-if="!subscriptionErrorMessage && !subscriptionBalanceExpiredByCredits" dense outlined color="primary">
                            <span style="color:green;">Last Credits Added Date</span>: {{ (new Date(latestSubscriptionCreditDate)).toString().split("GMT")[0] }}
                            <br />
                            <span style="color:red;">Last Credits Expiry Date</span>: {{ (new Date(latestSubscriptionExpiryDate)).toString().split("GMT")[0] }}
                        </v-alert>

                            <v-text-field
                                v-model="title"
                                counter="30"
                                :rules="titleRules"
                                label="Title"
                                required
                                :disabled="disabled"
                                clearable
                            ></v-text-field>
    
                            <v-textarea
                                :rules="descriptionRules"
                                v-model="description"
                                auto-grow
                                clearable
                                counter="500"
                                row-height="1"
                                label="Description of the video"
                                required
                                :disabled="disabled"
                            ></v-textarea>
    
                            <br />
    
                            <v-img
                                v-if="previewThumbnailImg != ''"
                                :src="previewThumbnailImg"
                            ></v-img>
    
                            <v-file-input
                                :disabled="disabled"
                                @change="previewThumbnail"
                                accept="image/*"
                                required
                                id="thumbnailInput"
                                :rules="FileRules"
                                prepend-icon="mdi-camera"
                                label="Select a Thumbnail"
                            >
                            </v-file-input>
    
                            <video
                                height="165"
                                style="width: 100%;"
                                autoplay
                                muted
                                loop
                                controls
                                :src="previewblob"
                                alt="please reselect the video"
                            ></video>
    
                            <v-file-input
                                :disabled="disabled"
                                @change="preview"
                                accept="video/*"
                                required
                                id="fileinput"
                                :rules="FileRules"
                                prepend-icon="mdi-video"
                                label="Select a Video"
                            >
                            </v-file-input>
    
    
                             <v-select
                                :disabled="disabled"
                                v-model="groupsListSelectedName"
                                :items="groupsList"
                                label="Select Group"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Subject Group is required']"
                                required
                                item-text="groupId.name"
                                item-value="groupId._id"
                                @change="getTaskList"
                            ></v-select>
    
    
                            <v-select
                                :disabled="disabled"
                                v-if="taskVisibility"
                                v-model="requestListSelectedName"
                                :items="requestList"
                                label="Select Task"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Subject Group is required']"
                                required
                                item-text="name"
                                item-value="_id"
                            ></v-select>

                            
                            <v-select
                                v-model="selectedCategory"
                                :items="categoriesList"
                                label="Select Category"
                                data-vv-name="select"
                                :rules="[(v) => !!v || 'Category of video is required']"
                                required
                                item-text="name"
                                item-value="_id"
                            ></v-select>

                            <v-text-field
                            prepend-icon="#"
                            v-model="videoTag"
                            counter="100"
                            label="Type a tag and press Enter"
                            hint="Hit Enter after typing to add a tag. You'll see them below as chips."
                            persistent-hint
                            required
                            clearable
                            v-on:keyup.enter="addTagToList"
                            ></v-text-field>

                            <div class="example-tags">
                                <p>Add hashtags like "videoresume", "yourdegree", "yourskill" so that recruiters 
                                    or other users on this platform can find your video when they search.</p>
                            </div>

                            <v-chip v-for="(item, index) in videoTags"
                                class="ma-2"
                                close
                                @click:close="removeTag(index)"
                                >
                                #{{item}}
                            </v-chip>
        
                            <br />
    
                            <p v-if="thumbnailProgressVisibility">
                                Uploading Thumbnail : {{ thumbanailProgressBar }} %
                            </p>
    
                            <v-progress-linear
                                color="light-blue"
                                height="10"
                                :value="thumbanailProgressBar"
                                striped
                                v-if="thumbnailProgressVisibility"
                            ></v-progress-linear>
    
                            <br />
    
                            <p v-if="progressvisibility">
                                Uploading Video : {{ progressbar }} %
                            </p>
    
                            <v-progress-linear
                                color="light-blue"
                                height="10"
                                :value="progressbar"
                                striped
                                v-if="progressvisibility"
                            ></v-progress-linear>
    
                            <br />
    
                            <div class="text-right">
    
                                <v-btn
                                class="ma-2"
                                color="primary"
                                depressed
                                v-on:click="validate"
                                :disabled="disabled"
                                >
    
                                    <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Upload
    
                                </v-btn>
    
                            </div>
    
                        </v-form>
    
                    </v-card>
    
                </v-flex>
    
                <v-flex xs11 md4>
                <v-card-title style="border: 5px solid #1976d2;border-radius: 10px;" class="headline" >Recommended for you to watch</v-card-title>
                <TrendingAndLatestFeed :showTheTopBar="false" v-if="userData.signedIn"/>

                <!-- <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="7895263089"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
    
                </v-card> -->
                
            </v-flex>
    
            </v-layout>
    
        </v-container>
    
    </div>
    </template>
    
    <style scoped>
    .example-tags {
        font-size: 0.8rem; /* Adjust the font size as needed */
        color: #666; /* Adjust the color */
        margin-top: 2px; /* Adjust the margin from the input field */
    }
    
    .sticky{
    position: relative;
    }
    
    
    
    .RightSideAd{
        padding-left: 0px;
        padding-right: 0px;
    }
    
    /* for desktop */
    @media screen and (min-width: 650px) {
    .RightSideAd{
        padding-left: 4%;
        padding-right: 4%;
    }
    
    .sticky{
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
        z-index: 2;
        width: auto !important;
    }
    
      .sticky2{
        position: -webkit-sticky;
        position: sticky;
        top: 27rem;
        z-index: 2;
        width: auto !important;
        margin-top: 280%;
    }
    }
    .form-container {
      padding   : 20px;
    }
    
    </style>
    
    <script>
    
    import { mapGetters } from "vuex";
    import utilities from "@/helpers/utilities";
    import apis from "../helpers/apiConfig";
    const axios = require("axios").default;
    import firebase from "firebase/app";
    import "firebase/auth";
    import "firebase/storage";
    
    import "firebase/firebase-analytics";
    var firebaseAnlytics;
    import TrendingAndLatestFeed from "@/components/TrendingAndLatestFeed";
    
    
    // Google Adsense    
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InFeedAdsense)
    
    export default {
        
        name        : "Pret",
        components: { TrendingAndLatestFeed },
        computed    : { ...mapGetters({ userData    : "User/userData" }) },
        
      
        mounted() {
    
            if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
            else { this.cardOutlineFlag = true; }
    
            // FIREBASE ANLYTICS
            firebaseAnlytics = firebase.analytics();
            firebaseAnlytics.logEvent("Pret Page");
            
            this.$store.commit("User/toggleProgressLoader", false);
            this.$store.dispatch("User/updateUserToken", null, { root: true });
            this.$store.commit("User/isSignedIn");
    
            this.getGroups();
            this.getCategories();
        
        },
        
        
        data() {
            
            return {
    
                cardFlatFlag    : true,
                cardOutlineFlag : false,
                
                previewThumbnailImg         : "",
                thumbnailFile               : "",
                thumbnailName               : "",
                videoName                   : "",
                thumbnailDownloadURL        : "",
                videoFile                   : "",
                previewblob                 : "",
                profilePhoto                : "",
                progressbar                 : 0,
                thumbanailProgressBar       : 0,
                thumbnailProgressVisibility : false,
                progressvisibility          : false,
                url                         : "This is url",
                disabled                    : false,
                valid                       : true,
                
                title                       : "",
                titleRules                  : [ (v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be less than 50 characters" ],
                
                description                 : "",
                descriptionRules            : [ (v) => !!v || "Curiculo Link is required", (v) => { if (v.length <= 500) return true; return "description must be less than 500 characters"; } ],
                
                FileRules                   : [(v) => !!v || "File is required"],
                select                      : null,
                groupsList                  : [],
                groupsListSelectedName      : "",
                requestList                 : [],
                requestListSelectedName     : "",
                taskVisibility              : false,
                subscriptionErrorMessage    : false,
                subscriptionBalanceExpiredByCredits: false,
                subscriptionBalanceExpiredByDate  : false,
                subscriptionBalanceLimit    : 0,

                categoriesList              : [],
                selectedCategory            : "",

                videoTags: [],
                videoTag: "",
                latestSubscriptionExpiryDate: "",
                latestSubscriptionCreditDate: "",                
            };
    
        },
        
        methods: {

            addTagToList() {
                if(this.videoTag) {
                    if(this.videoTags.length >= 5) {
                        this.$store.commit("User/showErrorAlert", "You can only add 5 tags.");
                    } else if(this.videoTag.length <= 1) {
                        this.$store.commit("User/showErrorAlert", "Tag length must be more than 1 character.");
                    } else if(this.videoTag.length > 100) {
                        this.$store.commit("User/showErrorAlert", "Tag length must be less than 100 characters.");
                    } else if(!this.videoTags.indexOf(this.videoTag)) {
                        this.$store.commit("User/showErrorAlert", "Duplicate tag.");
                    } else {
                        this.videoTags.push(this.videoTag);
                        this.videoTag = "";
                    }
                }
            },
            removeTag(index) {
                this.videoTags.splice(index, 1)
            },
            
            cancelFromEditing() {
                window.location.href = "/profile";
            },
    
            validate() {
                this.$refs.form.validate();

                if(this.videoTags.length === 0) {
                    const errorMsg = "Add at least one tag";
                    this.$store.commit("User/showErrorAlert", errorMsg);
                } else if (this.$refs.form.validate()) {
                    if(!this.subscriptionBalanceExpiredByCredits) {
                        if(!this.subscriptionBalanceExpiredByDate) {

                            if (this.groupsListSelectedName != "1234567890") {
                                //check for duplicate submission
                                let endPoint = apis.urls.duplicateSubmissionCheck;
                                                
                                axios(endPoint, {
                                    method          :   "get",
                                    params            : { taskId: this.requestListSelectedName},
                                    withCredentials :   true,
                                    headers         : { authorization: this.userData.token}
                                })
                                .then((res) => {
                                
                                    if(! res.data.result.isVideoSubmitted) {
                                        this.$store.commit("User/toggleProgressLoader", false);
                                        this.uploadThumbnail();
                                    }         
                                })
                                .catch((err) => {
                                    this.$store.commit("User/toggleProgressLoader", false);
                                    let errorMsg = utilities.errorHandler(err);
                                    this.$store.commit("User/showErrorAlert", errorMsg);
                                });

                            } else {
                                this.uploadThumbnail();
                            }

                        } else {
                            const errorMsg = "Your subscription is expired due to expiry date, Please recharge from Payment section or contact college to add more credits";
                            this.$store.commit("User/showErrorAlert", errorMsg);
                        }
                    } else {
                        const errorMsg = "Your subscription is expired due no more credits, Please recharge from Payment section or contact college to add more credits";
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    }
                }
            },
    
            reset() {
    
                this.$refs.form.reset();
                this.previewblob = "";
                this.previewThumbnailImg = "";
            
            },
    
            resetValidation() {
                this.$refs.form.resetValidation();
            },
    
            getGroups(){
                
                let endPoint = apis.urls.getFollowingGroups;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("Groups data : ",res);
                    
                    let data = res.data.result.data;
                    let subscriptionLength = res.data.result.subscriptionLength;
                    let subscriptionBalance = res.data.result.subscriptionBalance;
    
                    this.subscriptionBalanceLimit = res.data.result.subscriptionBalance;
   
                    
                    this.latestSubscriptionExpiryDate = res.data.result.latestSubscription.expiryDate;
                    this.latestSubscriptionCreditDate = res.data.result.latestSubscription.date;

                    console.log("latestSubscriptionExpiryDate: ", this.latestSubscriptionExpiryDate);
                    console.log("latestSubscriptionCreditDate: ", this.latestSubscriptionCreditDate);
                    
                    // this is to check whether user is new or not
                    if (subscriptionLength === 1 && subscriptionBalance === 1) {
                        this.subscriptionErrorMessage = true;
                    };
    
                    console.log("subscriptionBalance : ", subscriptionBalance);
    
                    // This is to check whether the balance of user is expired or not
                    if(subscriptionBalance <= 0) {
                        this.subscriptionBalanceExpiredByCredits = true;
                    }

                    if(new Date(this.latestSubscriptionExpiryDate) < new Date(Date.now())) {
                        this.subscriptionBalanceExpiredByDate = true;
                    }
    
                    this.groupsList = data;
                    this.groupsList.push({groupId: {name : "None", _id:"1234567890"}})
                    this.$store.commit("User/toggleProgressLoader", false);
    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Groups Data Retrived");
                
                }) 
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Following",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
            },
    
            getTaskList(){
                console.log("Group Selectd Name : ",this.groupsListSelectedName);
    
                if (this.groupsListSelectedName === "1234567890") {
                   this.taskVisibility = false;
                   this.requestListSelectedName = "something"
                } else {
                    this.taskVisibility = true;
                    let endPoint = apis.urls.getTasksList;
                    this.$store.commit("User/toggleProgressLoader", true);
                    
                    axios(endPoint, {
                        method          : "get",
                        params          : { groupId : this.groupsListSelectedName },
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
                        console.log("Tasks reqest data : ",res);
                        
                        let data = res.data.result.data;
                        this.requestList = data;
                        this.$store.commit("User/toggleProgressLoader", false);
    
                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Tasks Data Retrived");
                    
                    })
                    .catch((err) => {
                        
                        this.$store.commit("User/toggleProgressLoader", false);
                        console.log("Error in Following",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    
                    });
    
                }
                
    
    
            },

            getCategories(){
            
                let endPoint = apis.urls.getCategories;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("Categories data : ",res);
                    let data = res.data.result.data;
                    console.log("Categories that we got from backend : ", data)
                    this.categoriesList = data;
                    console.log("all rubrics data : ", this.categoriesList)
                    this.$store.commit("User/toggleProgressLoader", false);
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Categories Data Displayed");
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Getting Categories",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            },
    
            previewThumbnail(event) {
                
                var fileSize = event.size / 1024 / 1024;
                if (fileSize >= 100) {
                    
                    this.previewThumbnailImg = "";
                    this.$store.commit("User/showErrorAlert", "Thumbnail size is greater than 100 MB" );
                
                } else {
                    
                    this.thumbnailFile = event;
                    var that = this;
                    var reader = new FileReader();
                    
                    reader.onload = function(event) {
                        that.previewThumbnailImg = event.target.result.toString();
                    };
    
                    reader.readAsDataURL(event);
                
                }
    
            },
    
            preview(event) {
            
                var fileSize = event.size / 1024 / 1024;
                
                if (fileSize >= 1000) {
                
                    this.previewblob = "";
                    this.$store.commit("User/showErrorAlert", "Video size is greater than 1000 MB");
    
                } else {
                    
                    this.videoFile = event;
                    var that = this;
                    var reader = new FileReader();
                    
                    reader.onload = function(event) {
                        that.previewblob = event.target.result.toString();
                    };
                    
                    reader.readAsDataURL(event);
                }
            },
    
            makePret(downloadURL, finalName) {
                
                console.log("Now making pret by downloadURL : ", downloadURL);
                console.log("Now making pret by newname : ", finalName);
                this.url = downloadURL;
                this.$store.commit("User/toggleProgressLoader", false);

                const tagsFiltered = this.videoTags.map(tag => tag.startsWith("#") ? tag.substring(1) : tag);
                console.log("tagsFiltered : ", tagsFiltered);

                let data = {
                    title           : this.title,
                    description     : this.description,
                    url             : this.url,
                    firebaseName    : finalName,
                    thumbnailName   : this.thumbnailName,
                    thumbnailUrl    : this.thumbnailDownloadURL,
                    relativeThumbnailUrl : this.thumbnailName,
                    relativeVideoUrl : this.videoName,
                    categoryId      : this.selectedCategory,
                    tags            : tagsFiltered,
                };
    
                let endPoint = apis.urls.pret;
                this.$store.commit("User/toggleProgressLoader", true);
                
                if (this.groupsListSelectedName != "1234567890") {
                    endPoint = apis.urls.taskPret;
                    data.taskId = this.requestListSelectedName
                    data.groupId = this.groupsListSelectedName
                }
    
                
                axios(endPoint, {
                    method          : "post",
                    data,
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
    
                    console.log("success : ", res.data.result.message);
                    this.$store.commit("User/toggleProgressLoader", false);
                    this.disabled = false;
                    this.$store.commit("User/showSuccessAlert", res.data.result.message);
                    this.reset();
                    this.videoTags = [];
    
                    if(res.data.result.shouldRefresh) {
                        console.log("Refresh is needed")
                        this.subscriptionBalanceExpired = true;
                    } else {
                        console.log("no Refresh needed");
                    }
    
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Pret Uploaded");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    (this.disabled = false), console.log(err);
                    let errorMsg = utilities.errorHandler(err);
                    console.log("Error in Pret",err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });
    
            },
    
            uploadThumbnail() {
            
                this.disabled = true;
                this.thumbnailProgressVisibility = true;
                console.log("Thumbnail uploading started...");
                this.uploadImageAsPromise(this.thumbnailFile);
            
            },
    
            uploadVideo(newFolderName) {
                
                (this.progressvisibility = true),
                this.$store.commit("User/toggleProgressLoader", false);
    
                console.log("Video uploading started...");
                this.uploadVideoAsPromise(this.videoFile,newFolderName);
            
            },
    
            uploadImageAsPromise(thumbnailFile) {
                
                var newName        = " | Real Title : " + this.title  + " | Date : " + new Date(Date.now()).toUTCString();
                var newFolderName  = " | Real Title : " + this.title     + " | Date : " + new Date(Date.now()).toUTCString(); 
                var finalName   = "/prets/" + this.userData.email + "/" + newFolderName + "/" + newName;
                this.thumbnailName  = finalName;
                var storageRef      = firebase.storage().ref(finalName);
                var uploadTask      = storageRef.put(thumbnailFile);
    
                uploadTask.on("state_changed", (snapshot) => {
                    
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.thumbanailProgressBar = parseInt(progress);
                    
                    if (progress == 100) {
                    
                        this.thumbnailProgressVisibility = false;
                        this.$store.commit("User/toggleProgressLoader", true);
                    
                    }
                    
                    switch (snapshot.state) {
                    
                        case firebase.storage.TaskState.PAUSED:
                        console.log("Upload is paused");
                        break;
    
                        case firebase.storage.TaskState.RUNNING:
                        console.log("Upload is running");
                        break;
                    
                    }
                },
    
                function(error) {}, () => {
                    
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            
                        this.thumbnailDownloadURL = downloadURL;
                        this.uploadVideo(newFolderName);
                    
                    });
    
                });
    
            },
    
            uploadVideoAsPromise(videoFile,newFolderName) {
    
                var newName     = " | Real Title : " + this.title +" | Date : " + new Date(Date.now()).toUTCString();
                var finalName   = "/prets/" + this.userData.email + "/" + newFolderName + "/" + newName;
                this.videoName  = finalName;
                var storageRef  = firebase.storage().ref(finalName);
                var uploadTask  = storageRef.put(videoFile);
    
                uploadTask.on("state_changed", (snapshot) => {
                    
                    var progress        = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.progressbar    = parseInt(progress);
                    
                    if (progress == 100) {
    
                        this.progressvisibility = false;
                        this.$store.commit("User/toggleProgressLoader", true);
                    
                    }
                    
                    switch (snapshot.state) {
                    
                        case firebase.storage.TaskState.PAUSED:
                        console.log("Upload is paused");
                        break;
    
                        case firebase.storage.TaskState.RUNNING:
                        console.log("Upload is running");
                        break;
                    }
    
                },
                function(error) {}, () => {
                    
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        this.makePret(downloadURL, finalName);
                    });
    
                });
    
            }
    
        }
    
    };
    </script>