import { render, staticRenderFns } from "./Following.vue?vue&type=template&id=744b742e&scoped=true"
import script from "./Following.vue?vue&type=script&lang=js"
export * from "./Following.vue?vue&type=script&lang=js"
import style0 from "./Following.vue?vue&type=style&index=0&id=744b742e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "744b742e",
  null
  
)

export default component.exports