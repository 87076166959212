<template>
<div>
    
    <v-container>
        <!-- Sign in with us Dialogue -->
        <v-dialog v-model="signInWithUsDialog" persistent max-width="300">
            
            <v-card>
                
                <v-card-title class="headline">You need to Create Account with us </v-card-title>
                
                <v-card-text>Sign in with Google to upload Vidoes, Comment, Flag or like videos on Preskilet.</v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="signInWithUsRedirect" router> Sign in </v-btn>
                </v-card-actions>
            
            </v-card>
        
        </v-dialog>

        <v-dialog 
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="differentInstituion"
            persistent
        >

            <v-card>
                <v-progress-linear
                    v-if="updateInstitutionIdInProgress"
                    color="blue"
                    height="10"
                    indeterminate
                ></v-progress-linear>

                <v-toolbar color="green" dark flat>Belonging to organization</v-toolbar>
<!-- 
                <div style="display: grid;place-items: center;">

                    <v-img
      max-height="250"
      style="width: fit-content;margin: 5px;"
      :src="taskCreatorInstitutionDetails.logo"
    ></v-img>

                </div> -->

              
                <span v-if="!updateInstitutionIdInProgress">

                <v-card-title>{{ taskCreatorInstitutionDetails.name }}</v-card-title>

                <v-card-text>
                <div>
                    {{ taskCreatorInstitutionDetails.address }}
                    <br />
                    Visit website<a :href="taskCreatorInstitutionDetails.websiteLink" style="text-decoration: none;" target="_blank">&nbsp;&nbsp;<v-icon color="primary" size="14">mdi-launch</v-icon></a>
                    </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>Are you student at above institution ?</v-card-title>

                <v-card-actions class="justify-end">
                                
                                <v-spacer></v-spacer>
                                
                                <v-btn color="green darken-1" text @click="updateInstitutionId"> Yes </v-btn>
                                <v-btn color="green darken-1" text router @click="differentInstituion = false"> No </v-btn>
                            
                </v-card-actions>


                </span>

                <v-card-title v-if="updateInstitutionIdInProgress">Please wait, Making you part of organization...</v-card-title>
            </v-card>        
        </v-dialog>

        <v-layout row wrap justify-space-around>

            <v-flex xs11 md6>

                <p v-if="errorInGroupsList">Error while loading submission page, please try again later, or contact the task or group creator,
                    or reach out to us using following form
                    <a href="https://forms.gle/kDepe5crGeWVZrD49" target="_blank" rel="noopener noreferrer">Preskilet Help Form</a>
                </p>
                <p v-if="brokenUrl">The URL is broken, please check the URL shared by the group or task creator, or reach out to us using following form
                    <a href="https://forms.gle/kDepe5crGeWVZrD49" target="_blank" rel="noopener noreferrer">Preskilet Help Form</a>
                </p>

                <div v-if="userIsNotFolloweOfLockedGroup">
                    This group requires you to be follower before you submit the video, Please click on below follow button to send follow request, please contact group creator to accept your group request and then refresh page
                    <br />
                    <br />

                    <div style="border: 2px solid black; border-radius: 5px; background-color: whitesmoke; padding: 20px;">
                        <p>
                            Group Name: {{ submissionDetailsFromUrl.groupName }}
                        </p>

                        <v-btn @click="followGroup" class="ma-2" outlined color="primary"> Follow </v-btn>
                    </div>
                </div>

                <div v-if="userRequestIsNotAccepted">
                    Your group follow request is not yet accepted by the group & task creator, Please contact the group creator and refresh the page once again your request is accepted
                    <br />
                    <br />

                    <div style="border: 2px solid black; border-radius: 5px; background-color: whitesmoke; padding: 20px;">
                        <p>
                            Group Name: {{ submissionDetailsFromUrl.groupName }}
                        </p>

                        <v-btn diabled text color="grey"> Pending Request </v-btn>
                    </div>

                </div>
                
                <v-progress-linear
                    v-if="isFormDataPending"
                    color="light-blue"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>

                <p v-if="isFormDataPending">
                    Do not refresh, <br />
                    Opening the submission link
                </p>

                <p v-if="hideFormVideoUploaded">Your video is submitted, please explore other videos on the platform
                </p>

                <v-card v-if="!hideFormVideoUploaded && !isFormDataPending && !errorInGroupsList && !brokenUrl && !userIsNotFolloweOfLockedGroup && !userRequestIsNotAccepted" color="white" outlined>

                    <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="form-container"
                    >

                    <v-alert v-if="subscriptionErrorMessage" color="error" dense outlined type="error">
                            <h3 class="text-h5">
                                Only 1 video upload attempt
                            </h3>
                            <div>Thank you for choosing our platform!
                            You currently have 1 video credit, allowing you to only upload 1 video.
                            If you want upload additional videos,
                            <strong style="color: #2196F3;"> kindly reach out to your college management </strong>
                            to expand your video upload limit.</div>

                            <v-divider
                                class="my-4 info"
                                style="opacity: 0.22"
                            ></v-divider>

                            <strong style="color: green;">
                                        Or else You can recharge and get more video uploads credits by yourself
                                    </strong>

                            <v-row
                                align="center"
                                no-gutters
                            >
                                <v-col>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <router-link style="text-decoration: none;color:black;" to="/payments">
                                        <v-btn style="color: white;background-color: #2196F3;margin: 15px;">
                                            Recharge / Pay
                                        </v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                            </v-alert>

                        <v-alert v-if="subscriptionBalanceExpiredByCredits || subscriptionBalanceExpiredByDate" color="error" dense outlined type="error">
                            <h3 class="text-h5">
                                Subscription Expired
                            </h3>
                            <br />
                            <div>
                                Thank you for choosing our platform!
                                Your subscription has been expired because
                                <span v-if="subscriptionBalanceExpiredByCredits" style="color: black;">exipry date is passed</span>
                                <span v-if="subscriptionBalanceExpiredByDate" style="color: black;">video credit is reached to zero (0)</span>
                                If you want upload additional videos,
                            <strong style="color: #2196F3;"> kindly reach out to your college management </strong>
                            to expand your video upload limit.</div>

                            <v-divider
                                class="my-4 info"
                                style="opacity: 0.22"
                            ></v-divider>

                            <strong style="color: green;">
                                        Or else You can recharge and get more video uploads credits by yourself
                                    </strong>

                            <v-row
                                align="center"
                                no-gutters
                            >
                                <v-col>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <router-link style="text-decoration: none;color:black;" to="/payments">
                                        <v-btn style="color: white;background-color: #2196F3;margin: 15px;">
                                            Recharge / Pay
                                        </v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                        </v-alert>
                            
                        <v-alert v-if="!subscriptionErrorMessage && !subscriptionBalanceExpiredByDate && !subscriptionBalanceExpiredByCredits" dense outlined color="primary">
                            You currently have <span style="color:red">{{ subscriptionBalanceLimit }} video upload attempt</span>, allowing you to upload {{ subscriptionBalanceLimit  }} videos.
                            If you want upload additional videos,
                            <strong> Please recharge from Payment section or kindly reach out to your college management </strong>
                            to expand your video upload limit.
                        </v-alert>

                        <v-alert v-if="!subscriptionErrorMessage && !subscriptionBalanceExpiredByCredits" dense outlined color="primary">
                            <span style="color:green;">Last Credits Added Date</span>: {{ (new Date(latestSubscriptionCreditDate)).toString().split("GMT")[0] }}
                            <br />
                            <span style="color:red;">Last Credits Expiry Date</span>: {{ (new Date(latestSubscriptionExpiryDate)).toString().split("GMT")[0] }}
                        </v-alert>


                    <v-select
                            disabled
                            v-model="submissionDetailsFromUrl.groupId"
                            :items="groupsList"
                            label="Select Group"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Subject Group is required']"
                            required
                            item-text="groupId.name"
                            item-value="groupId._id"
                            @change="getTaskList"
                        ></v-select>

                        <p style="color: red;font-size: 14px;" v-if="showAutoJoinWarning">
                            <i>Uploading video here, will make you the follower of above group</i>
                        </p>

                        <v-select
                            disabled
                            v-model="submissionDetailsFromUrl.taskId"
                            :items="requestList"
                            label="Select Task"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Subject Group is required']"
                            required
                            item-text="name"
                            item-value="_id"
                        ></v-select>

                        <v-select
                            disabled
                            v-model="submissionDetailsFromUrl.categoryId"
                            :items="categoriesList"
                            label="Select Category"
                            data-vv-name="select"
                            :rules="[(v) => !!v || 'Category of video is required']"
                            required
                            item-text="name"
                            item-value="_id"
                        ></v-select>



                        <v-text-field
                            v-model="title"
                            counter="30"
                            :rules="titleRules"
                            label="Title"
                            required
                            :disabled="disabled"
                            clearable
                        ></v-text-field>

                        <v-textarea
                            :rules="descriptionRules"
                            v-model="description"
                            auto-grow
                            clearable
                            counter="500"
                            row-height="1"
                            label="Description of the video"
                            required
                            :disabled="disabled"
                        ></v-textarea>


                        <v-text-field
                            prepend-icon="#"
                            v-model="videoTag"
                            counter="100"
                            label="Add tags for your video"
                            required
                            :disabled="disabled"
                            clearable
                            v-on:keyup.enter="addTagToList"
                        ></v-text-field>

                        <div class="example-tags">
                                <p>Add hashtags like "videoresume", "yourdegree", "yourskill" so that recruiters 
                                    or other users on this platform can find your video when they search.</p>
                            </div>

                            <v-chip v-for="(item, index) in videoTags"
                                class="ma-2"
                                close
                                @click:close="removeTag(index)"
                                >
                                #{{item}}
                            </v-chip>
                        <br />

                        <v-img
                            v-if="previewThumbnailImg != ''"
                            :src="previewThumbnailImg"
                        ></v-img>

                        <v-file-input
                            :disabled="disabled"
                            @change="previewThumbnail"
                            accept="image/*"
                            required
                            id="thumbnailInput"
                            :rules="FileRules"
                            prepend-icon="mdi-camera"
                            label="Select a Thumbnail"
                        >
                        </v-file-input>

                        <video
                            height="165"
                            style="width: 100%;"
                            autoplay
                            muted
                            loop
                            controls
                            :src="previewblob"
                            alt="please reselect the video"
                        ></video>

                        <v-file-input
                            :disabled="disabled"
                            @change="preview"
                            accept="video/*"
                            required
                            id="fileinput"
                            :rules="FileRules"
                            prepend-icon="mdi-video"
                            label="Select a Video"
                        >
                        </v-file-input>

                        <br />

                        <p v-if="followRequestLoading">
                            Do not refresh, <br />
                            Please wait, Making you part of the group
                        </p>

                        <v-progress-linear
                            v-if="followRequestLoading"
                            color="light-blue"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>

                        <p v-if="thumbnailProgressVisibility">
                            Do not refresh, <br />
                            Uploading Thumbnail : {{ thumbanailProgressBar }} %
                        </p>

                        <v-progress-linear
                            color="light-blue"
                            height="10"
                            :value="thumbanailProgressBar"
                            striped
                            v-if="thumbnailProgressVisibility"
                        ></v-progress-linear>

                        <br />

                        <p v-if="progressvisibility">
                            Do not refresh, <br />
                            Uploading Video : {{ progressbar }} %
                        </p>

                        <v-progress-linear
                            color="light-blue"
                            height="10"
                            :value="progressbar"
                            striped
                            v-if="progressvisibility"
                        ></v-progress-linear>

                        <br />

                        <div class="text-right">

                            <v-btn
                            class="ma-2"
                            color="primary"
                            depressed
                            v-on:click="validate"
                            :disabled="disabled"
                            >

                                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>Upload

                            </v-btn>

                        </div>

                    </v-form>

                </v-card>

            </v-flex>

            <v-flex xs11 md4>
                <v-card-title style="border: 5px solid #1976d2;border-radius: 10px;" class="headline" >Recommended for you to watch</v-card-title>
                <TrendingAndLatestFeed :showTheTopBar="false" v-if="userData.signedIn"/>

                <!-- <v-card 
                    :flat="cardFlatFlag"
                    :outlined="cardOutlineFlag"
                    class="mx-auto sticky"
                >
            
                    <v-list-item two-line class="RightSideAd">
                        
                        <v-list-item-avatar><img src="https://banner2.cleanpng.com/20180324/iww/kisspng-google-logo-g-suite-google-5ab6f1cee66464.5739288415219388949437.jpg" /></v-list-item-avatar>
                        
                        <v-list-item-content>
                            
                            <v-list-item-title>Google Ads</v-list-item-title>
                            
                            <v-list-item-subtitle>Sponsored</v-list-item-subtitle>
                        
                        </v-list-item-content>
                                    
                        <v-btn color="primary" size="35" tile large icon router >
                            <v-icon left> mdi-compass-outline</v-icon>
                        </v-btn>


                    </v-list-item>
                    
                        <div class="ad-container">
                            <Adsense
                                style="display:block"
                                data-ad-client="ca-pub-2789565594210548"
                                data-ad-slot="7895263089"
                                data-ad-format="auto"
                                data-full-width-responsive="true"
                            ></Adsense>
                        </div>
    
                </v-card> -->
                
            </v-flex>

        </v-layout>

    </v-container>

</div>
</template>

<style scoped>
.sticky{
position: relative;
}

.example-tags {
        font-size: 0.8rem; /* Adjust the font size as needed */
        color: #666; /* Adjust the color */
        margin-top: 2px; /* Adjust the margin from the input field */
    }



.RightSideAd{
    padding-left: 0px;
    padding-right: 0px;
}

/* for desktop */
@media screen and (min-width: 650px) {
.RightSideAd{
    padding-left: 4%;
    padding-right: 4%;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 2;
    width: auto !important;
}

  .sticky2{
    position: -webkit-sticky;
    position: sticky;
    top: 27rem;
    z-index: 2;
    width: auto !important;
    margin-top: 280%;
}
}
.form-container {
  padding   : 20px;
}

</style>

<script>

import { mapGetters } from "vuex";
import utilities from "@/helpers/utilities";
import apis from "../helpers/apiConfig";
const axios = require("axios").default;
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

import "firebase/firebase-analytics";
var firebaseAnlytics;

// Google Adsense    
import Vue from "vue";
import Ads from 'vue-google-adsense'
import TrendingAndLatestFeed from "@/components/TrendingAndLatestFeed";

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InFeedAdsense)

export default {
    
    name        : "SubmissionVideoForTask",
    components: { TrendingAndLatestFeed },

    computed    : { ...mapGetters({ userData    : "User/userData" }) },
  
    mounted() {

        if (window.screen.availWidth <= 650) { this.cardFlatFlag = true; } 
        else { this.cardOutlineFlag = true; }

        // FIREBASE ANLYTICS
        firebaseAnlytics = firebase.analytics();
        firebaseAnlytics.logEvent("Pret Page");
        
        this.$store.commit("User/toggleProgressLoader", false);
        this.$store.dispatch("User/updateUserToken", null, { root: true });
        this.$store.commit("User/isSignedIn");

        if(!this.userData.signedIn) { 
            this.signInWithUsDialog = true;
        } else {
            this.getGroups();
        }
    },
    
    
    data() {
        
        return {
            signInWithUsDialog: false,

            cardFlatFlag    : true,
            cardOutlineFlag : false,
            
            previewThumbnailImg         : "",
            thumbnailFile               : "",
            thumbnailName               : "",
            videoName                   : "",
            thumbnailDownloadURL        : "",
            videoFile                   : "",
            previewblob                 : "",
            profilePhoto                : "",
            progressbar                 : 0,
            thumbanailProgressBar       : 0,
            thumbnailProgressVisibility : false,
            progressvisibility          : false,
            url                         : "This is url",
            disabled                    : false,
            valid                       : true,
            
            title                       : "",
            titleRules                  : [ (v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be less than 50 characters" ],
            
            description                 : "",
            descriptionRules            : [ (v) => !!v || "Curiculo Link is required", (v) => { if (v.length <= 500) return true; return "description must be less than 500 characters"; } ],
            
            FileRules                   : [(v) => !!v || "File is required"],
            select                      : null,
            categoriesList                : [],
            groupsList                  : [],
            groupsListSelectedName      : "",
            requestList                 : [],
            requestListSelectedName     : "",
            taskVisibility              : false,
            subscriptionErrorMessage    : false,
            subscriptionBalanceExpiredByCredits: false,
            subscriptionBalanceExpiredByDate  : false,
            subscriptionBalanceLimit    : 0,
            submissionDetailsFromUrl: {},
            errorInGroupsList: false,
            brokenUrl: false,
            userIsNotFolloweOfLockedGroup: false,
            userRequestIsNotAccepted: false,
            isFormDataPending: true,
            videoTags: [],
            videoTag: "",
            showAutoJoinWarning: false,
            followRequestLoading: false,
            differentInstituion: false,
            taskCreatorInstitutionDetails: {},
            updateInstitutionIdInProgress: false,
            hideFormVideoUploaded: false,
            latestSubscriptionExpiryDate: "",
            latestSubscriptionCreditDate: "",
        };

    },
    
    methods: {
        updateInstitutionId() {
            this.updateInstitutionIdInProgress = true;

            let endPoint = apis.urls.updateInstitutionId;
            
            axios(endPoint, {
                method: "post",
                data: { institutionId: this.submissionDetailsFromUrl.institutionId},
                withCredentials: true,
                headers: { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("success : ", res.data.result.message);
                this.$store.commit("User/showSuccessAlert", "Profile updated");
                this.updateInstitutionIdInProgress = false;
                this.differentInstituion = false;
                
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Credits added");
            })
            .catch((err) => {
                this.updateInstitutionIdInProgress = false;
                this.differentInstituion = false;

                console.log("Error In updateInstitutionId : ", err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            });
        },

        signInWithUsRedirect() { window.location.replace("/signin") },

        addTagToList() {
            if(this.videoTag) {
                if(this.videoTags.length >= 5) {
                    this.$store.commit("User/showErrorAlert", "You can only add 5 tags.");
                } else if(this.videoTag.length <= 1) {
                    this.$store.commit("User/showErrorAlert", "Tag length must be more than 1 character.");
                } else if(this.videoTag.length > 100) {
                    this.$store.commit("User/showErrorAlert", "Tag length must be less than 100 characters.");
                } else if(!this.videoTags.indexOf(this.videoTag)) {
                    this.$store.commit("User/showErrorAlert", "Duplicate tag.");
                } else {
                    this.videoTags.push(this.videoTag);
                    this.videoTag = "";
                }
            }
        },
        removeTag(index) {
            if(index !== 0) {
                this.videoTags.splice(index, 1)
            } else {
                this.$store.commit("User/showErrorAlert", "You can not remove this tag, as it is the category of this submission.");
            }
        },
        setDataInFormFromURLS () {
            console.log("this.userData.profile.institutionId : ", this.userData.profile.institutionId);
            console.log("this.submissionDetailsFromUrl.institutionId : ", this.submissionDetailsFromUrl.institutionId);

            if(JSON.stringify(this.userData.profile.institutionId) === JSON.stringify(this.submissionDetailsFromUrl.institutionId)){
                console.log("Same Institution")
            } else {
                console.log("Different Institution")
                
                let endPoint = apis.urls.getInstitutionDetailsById;
                
                axios(endPoint, {
                    method          : "get",
                    params          : { institutionId : this.submissionDetailsFromUrl.institutionId },
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("taskCreatorInstitutionDetails : ", res);
                    this.taskCreatorInstitutionDetails = res.data.result.data[0];
                    this.differentInstituion = true;

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("getInstitutionDetailsById Retrived");
                })
                .catch((err) => {
                    console.log("Error in getInstitutionDetailsById : ", err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            }

            this.groupsListSelectedName = this.submissionDetailsFromUrl.groupId;
            this.getTaskList();

            const videoCategory = this.categoriesList.find((item) => {
                return item._id === this.submissionDetailsFromUrl.categoryId;
            })

            console.log("video category :", videoCategory)
            this.videoTags[0] = videoCategory.name;
            
        },
        validateUserGroupRelation () {
            console.log("this.groupsList : ", this.groupsList);
            console.log("got the groupId : ", this.submissionDetailsFromUrl.groupId);
            
            const isUserFollower = this.groupsList.find((groupItem) => {
                return groupItem?.groupId?._id === this.submissionDetailsFromUrl.groupId;
            })

            console.log("isUserFollower : ", isUserFollower);

            if(isUserFollower) {
                console.log("User is part of the group");
                this.setDataInFormFromURLS();
            } else {
                console.log("User is NOT part of the group");
                console.log("this.submissionDetailsFromUrl : ", this.submissionDetailsFromUrl);

                if(this.submissionDetailsFromUrl.autoJoin) {
                    this.showAutoJoinWarning = true;

                    this.groupsList.push({
                        groupId: {
                            _id: this.submissionDetailsFromUrl.groupId,
                            name: this.submissionDetailsFromUrl.groupName,
                        }
                    });
                    console.log("New groupList : ", this.groupsList);
                
                    this.setDataInFormFromURLS();
                } else {
                    console.log("Auto Join is false");
                    
                    let endPoint = apis.urls.getFollowingGroupIds;
            
                    axios(endPoint, {
                        method          : "get",
                        withCredentials : true,
                        headers         : { authorization: this.userData.token }
                    })
                    .then((res) => {
                        let data = res.data.result.data;
                        const pendingGroups = data.pending;
                        console.log("pendingGroups : ", pendingGroups);
                        
                        if(pendingGroups.includes(this.submissionDetailsFromUrl.groupId)) {
                            this.userRequestIsNotAccepted = true;
                        } else {
                            this.userIsNotFolloweOfLockedGroup = true;
                        }

                        // FIREBASE ANLYTICS
                        firebaseAnlytics.logEvent("Group Following ID Retrived");

                    })
                    .catch((err) => {
                        console.log("Error in Following",err);
                        let errorMsg = utilities.errorHandler(err);
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    });
                }
                return false;
            }
        },

        fillSubmissionDetailsFromUrl() {
            this.isFormDataPending = false;
            
            if(this.$route.query.groupId &&
                this.$route.query.groupName &&
                this.$route.query.autoJoin &&
                this.$route.query.taskId &&
                this.$route.query.categoryId &&
                this.$route.query.institutionId
            ){
                this.submissionDetailsFromUrl = {
                    groupId: this.$route.query.groupId,
                    groupName: this.$route.query.groupName,
                    autoJoin: this.$route.query.autoJoin === "true" ? true : false,
                    taskId: this.$route.query.taskId,
                    categoryId: this.$route.query.categoryId,
                    institutionId: this.$route.query.institutionId
                };

                this.validateUserGroupRelation()
            } else {
                this.brokenUrl = true;
            }
        },

        cancelFromEditing() {
            window.location.href = "/profile";
        },

        validate() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                if(!this.subscriptionBalanceExpiredByCredits) {
                    if(!this.subscriptionBalanceExpiredByDate) {
                        this.checkFollowRequestSendingIsRequired()
                    } else {
                        const errorMsg = "Your subscription is expired due to expiry date, Please recharge from Payment section or contact college to add more credits";
                        this.$store.commit("User/showErrorAlert", errorMsg);
                    }
                } else {
                    const errorMsg = "Your subscription is expired due no more credits, Please recharge from Payment section or contact college to add more credits";
                    this.$store.commit("User/showErrorAlert", errorMsg);
                }
            }
        },

        followGroup() {
            this.$store.commit("User/toggleProgressLoader", true);
            let endPoint = apis.urls.followGroup;

            axios(endPoint, {
                    method          :   "post",
                    data            : { groupId: this.$route.query.groupId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token}
                })
                .then((res) => {
                    console.log("followed group : ",res);
                    this.userIsNotFolloweOfLockedGroup = false;
                    this.userRequestIsNotAccepted = true;

                    this.$store.commit("User/showSuccessAlert", "Group join request sent, Please contact your group creator to accept the request!");
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Group Follow Operation");                
                })
                .catch((err) => {
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });


        },

        checkFollowRequestSendingIsRequired() {
            console.log("checkFollowRequestSendingIsRequired called");
            console.log("this.showAutoJoinWarning : ", this.showAutoJoinWarning);
            if(this.showAutoJoinWarning) {
                // making user the member of this group first
                this.followRequestLoading = true;
                
                let endPoint = apis.urls.followGroup;
                
                axios(endPoint, {
                    method          :   "post",
                    data            : { groupId: this.submissionDetailsFromUrl.groupId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token}
                })
                .then((res) => {
                    console.log("followed group : ",res);
                    console.log("res.data.result.isAccepted : ", res.data.result.isAccepted);
                    if(res.data.result.isAccepted) {
                        this.$store.commit("User/showSuccessAlert", "You are following the group!");
                        this.uploadThumbnail();
                    }
                   
                    this.followRequestLoading = false;
                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("New Group Follow Operation");                
                })
                .catch((err) => {
                    this.followRequestLoading = false;

                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            } else {

                //check for duplicate submission
                let endPoint = apis.urls.duplicateSubmissionCheck;
                
                axios(endPoint, {
                    method          :   "get",
                    params            : { taskId: this.submissionDetailsFromUrl.taskId},
                    withCredentials :   true,
                    headers         : { authorization: this.userData.token}
                })
                .then((res) => {
                   
                    if(! res.data.result.isVideoSubmitted) {
                        this.$store.commit("User/toggleProgressLoader", false);
                        this.uploadThumbnail();
                    }         
                })
                .catch((err) => {
                    this.$store.commit("User/toggleProgressLoader", false);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                });
            }
        },

        reset() {

            this.$refs.form.reset();
            this.previewblob = "";
            this.previewThumbnailImg = "";
        
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },

        getGroups(){
            
            let endPoint = apis.urls.getFollowingGroups;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Groups data : ",res);
                
                let data = res.data.result.data;
                let subscriptionLength = res.data.result.subscriptionLength;
                let subscriptionBalance = res.data.result.subscriptionBalance;

                this.subscriptionBalanceLimit = res.data.result.subscriptionBalance;

                this.latestSubscriptionExpiryDate = res.data.result.latestSubscription.expiryDate;
                this.latestSubscriptionCreditDate = res.data.result.latestSubscription.date;

                console.log("latestSubscriptionExpiryDate: ", this.latestSubscriptionExpiryDate);
                console.log("latestSubscriptionCreditDate: ", this.latestSubscriptionCreditDate);

                
                // this is to check whether user is new or not
                if (subscriptionLength === 1 && subscriptionBalance === 1) {
                    this.subscriptionErrorMessage = true;
                };

                console.log("subscriptionBalance : ", subscriptionBalance);

                // This is to check whether the balance of user is expired or not
                if(subscriptionBalance <= 0) {
                    this.subscriptionBalanceExpiredByCredits = true;
                }

                if(new Date(this.latestSubscriptionExpiryDate) < new Date(Date.now())) {
                    this.subscriptionBalanceExpiredByDate = true;
                }

                this.groupsList = data;
                this.$store.commit("User/toggleProgressLoader", false);

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Groups Data Retrived");

                console.log("this is submission page")
                this.errorInGroupsList = false;
                this.getCategories();

            }) 
            .catch((err) => {
                this.errorInGroupsList = true;
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in while getting groups : ",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        getTaskList(){
            console.log("Group Selectd Name : ",this.groupsListSelectedName);

            if (this.groupsListSelectedName === "1234567890") {
               this.taskVisibility = false;
               this.requestListSelectedName = "something"
            } else {
                this.taskVisibility = true;
                let endPoint = apis.urls.getTasksList;
                this.$store.commit("User/toggleProgressLoader", true);
                
                axios(endPoint, {
                    method          : "get",
                    params          : { groupId : this.groupsListSelectedName },
                    withCredentials : true,
                    headers         : { authorization: this.userData.token }
                })
                .then((res) => {
                    console.log("Tasks reqest data : ",res);
                    
                    let data = res.data.result.data;
                    this.requestList = data;
                    this.$store.commit("User/toggleProgressLoader", false);

                    // FIREBASE ANLYTICS
                    firebaseAnlytics.logEvent("Tasks Data Retrived");
                
                })
                .catch((err) => {
                    
                    this.$store.commit("User/toggleProgressLoader", false);
                    console.log("Error in Following",err);
                    let errorMsg = utilities.errorHandler(err);
                    this.$store.commit("User/showErrorAlert", errorMsg);
                
                });

            }
            


        },

        getCategories(){
            
            let endPoint = apis.urls.getCategories;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "get",
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {
                console.log("Categories data : ",res);
                let data = res.data.result.data;
                console.log("Categories that we got from backend : ", data)
                this.categoriesList = data;
                console.log("all rubrics data : ", this.categoriesList)
                
                // This start the next exicution 
                this.fillSubmissionDetailsFromUrl();
                
                this.$store.commit("User/toggleProgressLoader", false);
                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("Categories Data Displayed");
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                console.log("Error in Getting Categories",err);
                let errorMsg = utilities.errorHandler(err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },


        previewThumbnail(event) {
            
            var fileSize = event.size / 1024 / 1024;
            if (fileSize >= 100) {
                
                this.previewThumbnailImg = "";
                this.$store.commit("User/showErrorAlert", "Thumbnail size is greater than 100 MB" );
            
            } else {
                
                this.thumbnailFile = event;
                var that = this;
                var reader = new FileReader();
                
                reader.onload = function(event) {
                    that.previewThumbnailImg = event.target.result.toString();
                };

                reader.readAsDataURL(event);
            
            }

        },

        preview(event) {
        
            var fileSize = event.size / 1024 / 1024;
            
            if (fileSize >= 1000) {
            
                this.previewblob = "";
                this.$store.commit("User/showErrorAlert", "Video size is greater than 1000 MB");

            } else {
                
                this.videoFile = event;
                var that = this;
                var reader = new FileReader();
                
                reader.onload = function(event) {
                    that.previewblob = event.target.result.toString();
                };
                
                reader.readAsDataURL(event);
            }
        },

        makePret(downloadURL, finalName) {
            
            console.log("Now making pret by downloadURL : ", downloadURL);
            console.log("Now making pret by newname : ", finalName);
            this.url = downloadURL;
            this.$store.commit("User/toggleProgressLoader", false);

            const tagsFiltered = this.videoTags.map(tag => tag.startsWith("#") ? tag.substring(1) : tag);
            console.log("tagsFiltered : ", tagsFiltered);

            let data = {
                title           : this.title,
                description     : this.description,
                url             : this.url,
                firebaseName    : finalName,
                thumbnailName   : this.thumbnailName,
                thumbnailUrl    : this.thumbnailDownloadURL,
                relativeThumbnailUrl : this.thumbnailName,
                relativeVideoUrl : this.videoName,
                taskId          : this.submissionDetailsFromUrl.taskId,
                categoryId      : this.submissionDetailsFromUrl.categoryId,
                tags            : tagsFiltered,
                groupId         : this.submissionDetailsFromUrl.groupId
            };

            let endPoint = apis.urls.taskPret;
            this.$store.commit("User/toggleProgressLoader", true);
            
            axios(endPoint, {
                method          : "post",
                data,
                withCredentials : true,
                headers         : { authorization: this.userData.token }
            })
            .then((res) => {

                console.log("success : ", res.data.result.message);
                this.$store.commit("User/toggleProgressLoader", false);
                this.disabled = false;
                this.$store.commit("User/showSuccessAlert", res.data.result.message);
                this.reset();

                if(res.data.result.shouldRefresh) {
                    console.log("Refresh is needed")
                    this.subscriptionBalanceExpired = true;
                } else {
                    console.log("no Refresh needed");
                }

                // FIREBASE ANLYTICS
                firebaseAnlytics.logEvent("New Pret Uploaded");

                this.hideFormVideoUploaded = true;
            })
            .catch((err) => {
                
                this.$store.commit("User/toggleProgressLoader", false);
                (this.disabled = false), console.log(err);
                let errorMsg = utilities.errorHandler(err);
                console.log("Error in Pret",err);
                this.$store.commit("User/showErrorAlert", errorMsg);
            
            });

        },

        uploadThumbnail() {
        
            this.disabled = true;
            this.thumbnailProgressVisibility = true;
            console.log("Thumbnail uploading started...");
            this.uploadImageAsPromise(this.thumbnailFile);
        
        },

        uploadVideo(newFolderName) {
            
            (this.progressvisibility = true),
            this.$store.commit("User/toggleProgressLoader", false);

            console.log("Video uploading started...");
            this.uploadVideoAsPromise(this.videoFile,newFolderName);
        
        },

        uploadImageAsPromise(thumbnailFile) {
            
            var newName        = " | Real Title : " + this.title  + " | Date : " + new Date(Date.now()).toUTCString();
            var newFolderName  = " | Real Title : " + this.title     + " | Date : " + new Date(Date.now()).toUTCString(); 
            var finalName   = "/prets/" + this.userData.email + "/" + newFolderName + "/" + newName;
            this.thumbnailName  = finalName;
            var storageRef      = firebase.storage().ref(finalName);
            var uploadTask      = storageRef.put(thumbnailFile);

            uploadTask.on("state_changed", (snapshot) => {
                
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.thumbanailProgressBar = parseInt(progress);
                
                if (progress == 100) {
                
                    this.thumbnailProgressVisibility = false;
                    this.$store.commit("User/toggleProgressLoader", true);
                
                }
                
                switch (snapshot.state) {
                
                    case firebase.storage.TaskState.PAUSED:
                    console.log("Upload is paused");
                    break;

                    case firebase.storage.TaskState.RUNNING:
                    console.log("Upload is running");
                    break;
                
                }
            },

            function(error) {}, () => {
                
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        
                    this.thumbnailDownloadURL = downloadURL;
                    this.uploadVideo(newFolderName);
                
                });

            });

        },

        uploadVideoAsPromise(videoFile,newFolderName) {

            var newName     = " | Real Title : " + this.title +" | Date : " + new Date(Date.now()).toUTCString();
            var finalName   = "/prets/" + this.userData.email + "/" + newFolderName + "/" + newName;
            this.videoName  = finalName;
            var storageRef  = firebase.storage().ref(finalName);
            var uploadTask  = storageRef.put(videoFile);

            uploadTask.on("state_changed", (snapshot) => {
                
                var progress        = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.progressbar    = parseInt(progress);
                
                if (progress == 100) {

                    this.progressvisibility = false;
                    this.$store.commit("User/toggleProgressLoader", true);
                
                }
                
                switch (snapshot.state) {
                
                    case firebase.storage.TaskState.PAUSED:
                    console.log("Upload is paused");
                    break;

                    case firebase.storage.TaskState.RUNNING:
                    console.log("Upload is running");
                    break;
                }

            },
            function(error) {}, () => {
                
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    this.makePret(downloadURL, finalName);
                });

            });

        }

    }

};
</script>
