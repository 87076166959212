import firebase from "firebase/app";
import "firebase/auth";

// State object
const state = {
  token: "",
  signedIn: false,
  successAlert: false,
  successMsg: "Your operation has been successfully completed !",
  errorAlert: false,
  errorMsg: "Sorry ! Something went wrong, Please try again...",
  ProgressLoader: false,
  email: "",
  profile: {}
};

// Getter functions
const getters = {
  userData: (state) => {
    return state;
  },
};
// Actions
const actions = {
  updateUserToken: (context) => {
    // console.log("Update user token called from actions");
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        user
          .getIdToken(true)
          .then(function(userIdToken) {
            let payload = {
              userIdToken: userIdToken,
              signedIn: true,
              email: user.email,
            };
            context.commit("setUserToken", payload);
          })
          .catch(function(error) {
            console.log(error);
            let payload = {
              userIdToken: "",
              signedIn: false,
              email: "",
            };
            context.commit("setUserToken", payload);
            // window.location.href = "/signin";
          });
      } else {
        console.log("User not found yet ...");
        let payload = {
          userIdToken: "",
          signedIn: false,
          email: "",
        };
        context.commit("setUserToken", payload);
      }
    });
  },
};

// Mutations
const mutations = {

  updateUserProfile: (context, payload) => {
    state.profile = payload;
  },

  showSuccessAlert: (context, payload) => {
    state.successMsg = payload;
    state.successAlert = true;
    state.ProgressLoader = false;
  },
  showErrorAlert: (context, payload) => {
    state.errorMsg = payload || "Something went wrong !";
    state.errorAlert = true;
    state.ProgressLoader = false;
  },
  hideSuccessAlert: () => {
    state.successMsg = "";
    state.successAlert = false;
    state.ProgressLoader = false;
  },
  hideErrorAlert: () => {
    state.errorMsg = "";
    state.errorAlert = false;
    state.ProgressLoader = false;
  },
  toggleProgressLoader: (context, payload) => {
    // console.log("toggleProgressLoader state in user.store.js = ", payload);
    state.ProgressLoader = payload;
  },
  setUserToken: (context, payload) => {
    // console.log("Update user token called from mutations");
    window.localStorage.token = payload.userIdToken;
    window.localStorage.email = payload.email;
    // console.log("user token in local stoarage : ", window.localStorage.token);
    state.token = payload.userIdToken;
    state.signedIn = payload.signedIn;
    state.email = payload.email;

    console.log("setUserToken to be stoared token : ", payload.userIdToken);
  },

  isSignedIn: (state) => {
    // console.log("isSignedIn mutations called");
    let token = localStorage.getItem("token");
    console.log("isSignedIn locally stored token : ", token);

    if (token) {
      if(!state.token) {
        console.log("state token set from isSignedIn")
        state.token = token;
      }
      state.signedIn = true;
    } else {
      // state.token = "";
      state.signedIn = false;
    }
    let email = localStorage.getItem("email");
    if (email) {
      state.email = email;
    } else {
      state.email = "";
    }
  },
  signOut: (context) => {
    console.log("User siginout from vuex : ", context);
    window.localStorage.token = "";
    window.localStorage.email = "";
    window.localStorage.accountType = "";

    state.token = "";
    state.signedIn = "";
    state.email = "";
    state.accountType = "none";
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
